module controllers {
    export module documentManagement {
        interface IDocumentRepositoryFileEditScope extends ng.IScope {

        }

        interface IDocumentRepositoryFileModalItem {
            DocRep: interfaces.documentManagement.IDocumentRepository,
            DocFile: interfaces.documentManagement.IDocumentRepositoryFileView
        }

        export class documentRepositoryFileEditCtrl {
            static $inject = ['$scope', '$rootScope', '$q', '$uibModalInstance', 'items', 'ENV', 'documentRepositoryFileService', 'generalService',
                'bsLoadingOverlayService', 'documentTypeService', 'localeService', 'documentRepositoryService', "$timeout", "IsReadOnly"];

            title: string = "";

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            documentRepositoryFile: interfaces.documentManagement.IDocumentRepositoryFile;
            readOnly: boolean = false;

            uploadURL: string = "/notUpload";

            documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            repositoryTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            relatedTransactions: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            locales: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            fileToUpload: File;
            Messages: Array<interfaces.applicationcore.IMessageItem>;

            constructor(private $scope: IDocumentRepositoryFileEditScope, private $rootScope: interfaces.applicationcore.IRootScope, $q: ng.IQService,
                public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, private items: IDocumentRepositoryFileModalItem,
                private ENV: interfaces.applicationcore.serverConfig, public documentRepositoryFileService: interfaces.documentManagement.IDocumentRepositoryFileService,
                private generalService: interfaces.applicationcore.IGeneralService, private bsLoadingOverlayService,
                private documentTypeService: interfaces.documentManagement.IDocumentTypeService, private localeService: interfaces.applicationcore.ILocaleService,
                private documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService, private $timeout: ng.ITimeoutService, private IsReadOnly: boolean) {

                if (IsReadOnly)
                {
                    this.readOnly = IsReadOnly;
                }

                if (items.DocFile && items.DocFile.DocumentRepositoryId) {
                    this.title = "Edit Document Repository File";
                } else {
                    this.title = "New Document Repository File";
                }

                bsLoadingOverlayService.wrap({
                    referenceId: 'modal'
                }, () => {
                    //This used to only stop loading indicator when all data is loaded.
                    var promises: ng.IPromise<any>[] = [];

                    var docRepDefer = $q.defer();
                    promises.push(docRepDefer.promise);

                    /*
                        We have to load the correct document repository, this is important for dispay purposes and editing.
                    */
                    if (items.DocFile && items.DocRep.Id !== items.DocFile.DocumentRepositoryId) {
                        documentRepositoryService.getDocumentRepository().query({ docId: items.DocFile.DocumentRepositoryId, showAll:true }, (documentRepository) => {
                            this.documentRepository = documentRepository;

                            this.loadDocumentTypes().finally(() => {
                                docRepDefer.resolve();
                            });

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                        });
                    } else {
                        this.documentRepository = items.DocRep;
                        docRepDefer.resolve();

                        promises.push(this.loadDocumentTypes());
                    }
                    
                    if (items.DocFile) {
                        var repFilePromise = documentRepositoryFileService.getDocumentRepositoryFile().query({ Key: items.DocFile.Key }, (documentRepositoryFile) => {
                            this.documentRepositoryFile = documentRepositoryFile;
                            this.loadRepositoryTypes();
                            this.loadRelatedTransactions();
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                        }).$promise;

                        promises.push(repFilePromise);
                    } else {
                        this.documentRepositoryFile = <interfaces.documentManagement.IDocumentRepositoryFile>{
                            Source: "GTS_USER",
                            ReferenceNumber: items.DocRep.ReferenceNumber,
                            ReferenceDate: moment.utc(),
                            ReadOnly: false,
                            IsIntegrated: false,
                            IsVisibleGTSConnect: true,
                            FileLocaleCode: 'en-za',
                            DocumentRepositoryId: items.DocRep.Id,
                            Description: '',
                            DocumentTypeId: undefined,
                            FileId: undefined
                        }
                    }
                    
                    var localePromise = localeService.getLocale().query({}, (locales: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        this.locales = locales;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                        }).$promise;

                    promises.push(localePromise);

                    return $q.all(promises);
                });
            }

            

            changeDocType(modelId:number)
            {
                if (this.documentRepositoryFile.Description !== "" || !this.documentRepositoryFile.Description){
                    var docType = this.documentTypes.find(x=>x.Id === modelId);
                    this.documentRepositoryFile.Description = docType.Description;                    
                }

                this.loadRepositoryTypes();
            }

            changeRepoType(repositoryTypeId:number) {

                this.documentRepositoryFile.RepositoryTypeId = repositoryTypeId;

                this.loadRelatedTransactions();
            }

            ok() {
                this.bsLoadingOverlayService.start({
                    referenceId: 'modal'
                });

                if (!this.documentRepository.ReferenceNumber || !this.documentRepositoryFile.ReferenceDate || !this.documentRepositoryFile.DocumentTypeId || !this.documentRepositoryFile.RepositoryTypeId || !this.documentRepositoryFile.TransactionId)
                {
                    this.generalService.displayMessage("Not all required fields are selected.", Enum.EnumMessageType.Warning);
                    this.bsLoadingOverlayService.stop({
                        referenceId: 'modal'
                    });
                    return;
                }
                
                if (this.documentRepository.Id <= 0) {
                    this.saveDocumentRep();
                } else {
                    this.saveDocumentRepFile();
                }
            }

            cancel() {
                this.$uibModalInstance.dismiss(false);
            }

            saveDocumentRep() {
                this.documentRepositoryService.save().save(this.documentRepository, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.documentRepositoryFile.DocumentRepositoryId = Number(data.ID);
                    this.saveDocumentRepFile();
                }, (errorResponse) => {
                    this.bsLoadingOverlayService.stop({
                        referenceId: 'modal'
                    });
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                });  
            }

            saveDocumentRepFile() {
                this.documentRepositoryFileService.save().save(this.documentRepositoryFile, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data, this);
                    this.$uibModalInstance.close(true);
                }, (errorResponse) => {
                    this.bsLoadingOverlayService.stop({
                        referenceId: 'modal'
                    });
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                });  
            }

            private loadDocumentTypes() {
                return this.documentTypeService.getDocumentTypes().query({
                    repTypeId: this.documentRepository.RepositoryTypeId,
                    referenceNumber: this.documentRepository.ReferenceNumber,
                    ownerEntityId: this.documentRepository.OwnerEntityId
                }, (documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    this.documentTypes = documentTypes;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                }).$promise;
            }

            private loadRepositoryTypes() {
                //GetRepositoriesForDocumentType
                return this.documentTypeService.GetRepositoriesForDocumentType().query({
                    docTypeId: this.documentRepositoryFile.DocumentTypeId,
                    repTypeId: this.documentRepository.RepositoryTypeId,
                    referenceNumber: this.documentRepository.ReferenceNumber,
                    ownerEntityId: this.documentRepository.OwnerEntityId
                }, (repositoryTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    this.repositoryTypes = repositoryTypes;

                    if (this.repositoryTypes.length == 1) {
                       
                        this.changeRepoType(this.repositoryTypes[0].Id);
                    }

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                    }).$promise;
            }

            private loadRelatedTransactions() {
                return this.documentTypeService.GetRelatedTransactionsForRepositories().query({
                    selRepTypeId: this.documentRepositoryFile.RepositoryTypeId,
                    repTypeId: this.documentRepository.RepositoryTypeId,
                    referenceNumber: this.documentRepository.ReferenceNumber,
                    ownerEntityId: this.documentRepository.OwnerEntityId
                }, (relatedTransactions: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    this.relatedTransactions = relatedTransactions;

                    if (this.relatedTransactions.length == 1) {
                        this.documentRepositoryFile.TransactionId = this.relatedTransactions[0].Id;
                    }
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this);
                    }).$promise;
            }

        };

        angular.module("app").controller("documentRepositoryFileEditCtrl", controllers.documentManagement.documentRepositoryFileEditCtrl);
    }
}